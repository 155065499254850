import { useRef, useContext } from 'react';
import AuthContext from '../../store/auth-context';
import classes from './ProfileForm.module.css';
import { useHistory } from 'react-router-dom';

const ProfileForm = () => {
  const history = useHistory();
  const passInputRef = useRef();
  const passcInputRef = useRef();

  const authCtx = useContext(AuthContext);
  
  const submitHandler = (event) => {
    event.preventDefault();
    fetch('https://defaultnode.demostack.live/user/changepass', {
      method : 'PUT',
      headers : {
        'Authorization': 'bearer '+authCtx.token,
        'Content-Type' : 'application/json'
      },
      body: JSON.stringify({
        password : passInputRef.current.value,
        confirm_password: passcInputRef.current.value
      })
    }).then(res => {
      console.log(res);
      history.replace("/");
    }).catch(err => {
      alert(err.msg);
    });
  }
  return (
    <form className={classes.form} onSubmit={submitHandler}>
      <div className={classes.control}>
        <label htmlFor='new-password'>New Password</label>
        <input type='password' name="password" id='new-password' ref={passInputRef} />
      </div>
      <div className={classes.control}>
        <label htmlFor='confirm-new-password'>Confirm New Password</label>
        <input type='password' name='confirm_password' id='confirm-new-password' ref={passcInputRef} />
      </div>
      <div className={classes.action}>
        <button>Change Password</button>
      </div>
    </form>
  );
}

export default ProfileForm;
